<!-- 招聘会职位导入 -->
<template>
  <Modal v-model="is_import_show" title="导入历史职位" @on-visible-change="modalChange">
    <div class="job_fair_import">

      <Table :loading="loading" :columns="columns1" :data="Tabledata" border @on-selection-change="change_selection">

        <template slot-scope="{ row,index }" slot="details">
          <Button type="primary" size="small" @click="lookover(row)">查看详情</Button>
        </template>

      </Table>

      <!-- 查看详情Modal -->
      <Modal v-model="is_show" title="历史职位详情">
        <Form ref="formValidate" :model="formValidate" :label-width="100">

          <FormItem label="职位名称">
            <Input v-model="formValidate.name" placeholder="职位名称" readonly></Input>
          </FormItem>

          <FormItem label="招聘人数">
            <Input v-model="formValidate.number" placeholder="招聘人数" readonly>
            <span slot="append">人</span>
            </Input>
          </FormItem>

          <FormItem label="薪资">
            <Input v-model="formValidate.pay" placeholder="薪资" readonly>
            <span slot="append">元/月</span>
            </Input>
          </FormItem>

          <FormItem label="职位要求一">
            <Input v-model="formValidate.askfor_one" type="textarea" readonly></Input>
          </FormItem>

          <FormItem label="职位要求二">
            <Input v-model="formValidate.askfor_two" type="textarea" readonly></Input>
          </FormItem>

          <FormItem label="职位要求三">
            <Input v-model="formValidate.askfor_three" type="textarea" readonly></Input>
          </FormItem>

          <FormItem label="职位要求四">
            <Input v-model="formValidate.askfor_four" type="textarea" readonly></Input>
          </FormItem>

        </Form>
        <div slot="footer">
          <Button type="primary" @click="is_show = false">确定</Button>
        </div>
      </Modal>

    </div>
    <div slot="footer">

      <Button type="text" @click="is_import_show = false">取消</Button>
      <Button type="primary" @click="ok">确定导入</Button>

    </div>
  </Modal>
</template>

<script>
export default {
  data() {
    return {
      is_import_show: false,
      columns1: [
        {
          type: 'selection',
          width: 80,
          align: 'center',
        },
        {
          title: '职位名称',
          key: 'name',
          align: 'center',
        },
        {
          title: '招聘人数',
          key: 'number',
          align: 'center',
        },
        {
          title: '薪资',
          key: 'pay',
          align: 'center',
        },
        {
          title: '操作',
          slot: 'details',
          align: 'center',
        },
      ],
      Tabledata: [],
      is_show: false,
      loading: false,
      formValidate: {},
      selection_arr: [],

    }
  },
  props: ['Tabledata2'],
  methods: {
    //改变时
    modalChange(bool) {
      if (bool) {
        this.get_data()
      } else {
        this.selection_arr = []
      }
    },
    //查看
    lookover(row) {
      this.is_show = true
      this.formValidate = row
    },
    //表格选中项
    change_selection(arr) {
      console.log(arr);
      this.selection_arr = arr
    },
    //导入
    ok() {

      //没有导入选项
      if (this.selection_arr.length) {
        //当前选择超过4个
        if (this.selection_arr.length < 5) {
          if ((this.Tabledata2.length + this.selection_arr.length) > 4) {
            this.$Modal.warning({
              title: `招聘职位最多只能四个,上层页面已存在${this.Tabledata2.length}个`
            })
          } else {
            console.log("可以");
            this.$emit("import_job", this.selection_arr)
            this.is_import_show = false
          }
        } else {
          console.log(111);
          this.$Modal.warning({
            title: "招聘职位最多只能四个"
          })
        }
      } else {
        this.$Modal.warning({
          title: "请最少选择一项导入"
        })
      }



    },
    //封装获取数据
    get_data() {
      this.loading = true
      this.app("enterpriseOperators", "importJobFairPositions_v3", {
        mobile: localStorage.getItem('firm_mobile')
      }).then(res => {
        console.log(res);
        if (res.data.length > 0) {
          this.Tabledata = res.data
        } else {
          this.$Modal.warning({
            title: "暂无历史职位可导入！"
          })
        }
        this.loading = false
      })
    },
  },
  created() {

  }
}
</script>

<style lang="less" scoped>
// .job_fair_import {
// }
/deep/.ivu-modal {
  width: 1000px !important;
}
</style>
