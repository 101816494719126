<!-- 新增招聘职位 -->
<template>
  <div class="add_Position">
    <Modal v-model="add_Position" title="新增招聘职位" @on-visible-change="modalChange">
      <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="100">

        <FormItem label="职位名称" prop="name">
          <Input v-model="formValidate.name" placeholder="职位名称"></Input>
        </FormItem>

        <FormItem label="招聘人数" prop="number">
          <Input v-model="formValidate.number" placeholder="招聘人数">
          <span slot="append">人</span>
          </Input>
        </FormItem>

        <FormItem label="薪资" prop="pay">
          <Input v-model="formValidate.pay" placeholder="薪资">
          <span slot="append">元/月</span>
          </Input>
        </FormItem>

        <FormItem label="职位要求一" prop="askfor_one">
          <Input v-model="formValidate.askfor_one" placeholder="请输入（少于28个字）,职位要求第一个字不能是数字" maxlength="28" show-word-limit type="textarea"></Input>
        </FormItem>

        <FormItem label="职位要求二" prop="askfor_two">
          <Input v-model="formValidate.askfor_two" placeholder="请输入（少于28个字）,职位要求第一个字不能是数字" maxlength="28" show-word-limit type="textarea"></Input>
        </FormItem>

        <FormItem label="职位要求三">
          <Input v-model="formValidate.askfor_three" placeholder="请输入（少于28个字）,职位要求第一个字不能是数字" maxlength="28" show-word-limit type="textarea"></Input>
        </FormItem>

        <FormItem label="职位要求四">
          <Input v-model="formValidate.askfor_four" placeholder="请输入（少于28个字）,职位要求第一个字不能是数字" maxlength="28" show-word-limit type="textarea"></Input>
        </FormItem>

        <p style="text-align: center; color: red">标红星项为必填项，请按要求填写！</p>

      </Form>

      <div slot="footer">
        <Button type="text" @click="add_Position = false">取消</Button>
        <Button type="primary" @click="handleSubmit('formValidate')">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      add_Position: false,
      //是否修改
      is_edit: 100,
      formValidate: {
        name: "",
        number: "",
        pay: "",
        askfor_one: "",
        askfor_two: "",
        askfor_three: "",
        askfor_four: "",
      },
      ruleValidate: {
        name: [{ required: true, message: "请填写职位名称", trigger: "change" }],
        number: [{ required: true, message: "请填写招聘人数", trigger: "change" }],
        pay: [{ required: true, message: "请填写薪资", trigger: "change" }],
        askfor_one: [{ required: true, message: "请至少填写两个职位要求", trigger: "change" }],
        askfor_two: [{ required: true, message: "请至少填写两个职位要求", trigger: "change" }],

      }
    }
  },
  methods: {
    //开/关
    modalChange(bool) {
      if (bool) {
        this.$refs["formValidate"].resetFields();


      } else {
        //修改成默认添加职位状态
        this.is_edit = 100
        this.formValidate.askfor_three = ""
        this.formValidate.askfor_four = ""
      }
    },
    //验证数字
    verify(data) {
      return data <= '9' && data >= '0' ? true : false
    },
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          console.log(55);
          if (this.verify(this.formValidate.askfor_one[0]) ||
            this.verify(this.formValidate.askfor_two[0]) ||
            this.verify(this.formValidate.askfor_three[0]) ||
            this.verify(this.formValidate.askfor_four[0])
          ) {
            this.$Modal.warning({
              title: "职位要求开头不能是数字"
            })
          } else {
            console.log(55);
            console.log(this.is_edit);
            if (this.is_edit == 100) { //代表添加
             console.log(66);
              let new_data = JSON.parse(JSON.stringify(this.formValidate))
              this.$emit("addPosition", new_data)
              this.$Message.success("添加招聘职位成功")
              this.add_Position = false


            } else {   //其他数值为index 即编辑
 console.log(77);
              let new_data = JSON.parse(JSON.stringify(this.formValidate))
              this.$emit("edit_Position", new_data)
              this.$Message.success("编辑招聘职位成功")

              this.add_Position = false


            }



          }
        } else {
          this.$Message.error("请填写完整信息");
        }
      });
    }
  },
  created() {

  }
}
</script>

<style lang="less" scoped>
</style>
