<!-- 招聘会添加or详情 -->
<template>
  <div class="job_fair_details">

    <header>
      <Button size="small" type="warning" @click="$router.back()">
        <Icon type="ios-arrow-back" />
        返回
      </Button>
      <Divider type="vertical" />
      <p>{{this.$route.meta.title}}</p>
    </header>

    <div style="margin-bottom:10px">
      <span style="margin:0 20px">公司全称：{{firm_data.name}}</span>
      <span>企业地址：{{firm_data.address}}</span>
    </div>

    <!-- 表格 -->
    <section>
      <p class="text"><span>|参会代表</span><span>（最多两人）</span></p>
      <Table :columns="columns1" :data="Tabledata" border>
        <template slot-scope="{ row,index }" slot="details">
          <Button type="warning" size="small" @click="detele_manage(index)">删除</Button>
        </template>
      </Table>

      <Button v-show="Tabledata.length<2" style="margin-top:15px" type="warning" @click="add_manage">添加参会代表信息</Button>

    </section>

    <section style="margin-top:15px">
      <p class="text"><span>|招聘职位</span><span>（最多四个）</span></p>
      <Table :columns="columns2" :data="Tabledata2" border>
        <template slot-scope="{ row,index }" slot="edit_job">
          <div style="display:flex;justify-content: space-evenly">

            <Button type="warning" size="small" @click="edit_job(index)">编辑职位</Button>
            <Button type="warning" size="small" @click="detele_job(index)">删除职位</Button>
          </div>
        </template>

      </Table>
      <Button v-show="Tabledata2.length<4" style="margin:15px 20px 0 0" type="warning" @click="add_Position">新增招聘职位</Button>
      <!-- <Button v-show="Tabledata2.length<4" style="margin-top:15px" type="warning" @click="$router.push({name:'job_fair_import'})">导入历史职位</Button> -->

      <Button v-show="Tabledata2.length<4" style="margin-top:15px" type="warning" @click="import_Job">导入历史职位</Button>

    </section>

    <section style="margin-top:15px">
      <p class="text"><span>|企业福利</span></p>

      <Form ref="formValidate" :model="welfares[0]" :rules="ruleValidate" :label-width="120">

        <FormItem label="企业福利一" prop="welfare_one">
          <Input v-model="welfares[0].welfare_one" size="large" style="width:500px" maxlength="20" show-word-limit placeholder="请输入少于20个字"></Input>
        </FormItem>

        <FormItem label="企业福利二">
          <Input v-model="welfares[0].welfare_two" size="large" style="width:500px" maxlength="20" show-word-limit placeholder="请输入少于20个字"></Input>
        </FormItem>

        <FormItem label="企业福利三">
          <Input v-model="welfares[0].welfare_three" size="large" style="width:500px" maxlength="20" show-word-limit placeholder="请输入少于20个字"></Input>
        </FormItem>

      </Form>

    </section>
    <div style="text-align:right;margin:10px 0 50px">
      <Button :loading="loading" type="warning" @click="submit('formValidate')">提交招聘简章</Button>
    </div>

    <!-- 参会人员Modal -->
    <AddJob ref="AddJob" :Admin="Admin" @add_info="add_info" />
    <!-- 招聘职位Modal -->
    <AddPosition ref="AddPosition" @addPosition="addPost" @edit_Position="editPost" />
    <!-- 导入历史职位 -->
    <JobImport ref="JobImport" :Tabledata2='Tabledata2' @import_job="CallBack_import_job" />
  </div>
</template>

<script>
import AddJob from "./Modal/add_job.vue"
import AddPosition from "./Modal/add_Position.vue"
import JobImport from "../job_fair_import/job_fair_import.vue"
export default {
  data() {
    return {
      loading: false,
      //管理员信息
      Admin: {},
      //当前被点击编辑index
      edit_index: 0,
      columns1: [
        {
          title: '序号',
          type: 'index',
          width: 80,
          align: 'center'
        },
        {
          title: '参会代表姓名',
          key: 'allName',
          align: 'center',
        },
        {
          title: '职位',
          key: 'position',
          align: 'center',
        },
        {
          title: '手机',
          key: 'mobile',
          align: 'center',
        },
        {
          title: '邮箱',
          key: 'email',
          align: 'center',
        },
        {
          title: '操作',
          slot: 'details',
          align: 'center',
        },
      ],
      Tabledata: [],

      columns2: [
        {
          title: '序号',
          type: 'index',
          width: 80,
          align: 'center'
        },
        {
          title: '职位名称',
          key: 'name',
          align: 'center',
        },
        {
          title: '招聘人数',
          key: 'number',
          align: 'center',
        },
        {
          title: '薪资',
          key: 'pay',
          align: 'center',
        },
        {
          title: '操作',
          slot: 'edit_job',
          align: 'center',
        },
      ],
      Tabledata2: [],

      //福利
      welfares: [
        {
          welfare_one: '',
          welfare_two: '',
          welfare_three: ''
        }
      ],
      ruleValidate: {
        welfare_one: [{ required: true, message: "请填写至少一个福利", trigger: "change" }],
      }



    }
  },
  props: ["firm_data"],
  components: {
    AddJob,
    AddPosition,
    JobImport
  },
  methods: {

    //删除参会代表
    detele_manage(index) {
      this.$Modal.confirm({
        title: "确定删除该参会代表吗？",
        onOk: () => {
          this.Tabledata.splice(index, 1)
        }
      })
    },
    //添加参会代表
    add_manage() {
      this.$refs.AddJob.add_job = true
    },
    //添加参会返回数据
    add_info(data) {
      this.Tabledata.push(data)
    },
    //导入职位返回数据
    CallBack_import_job(arr) {
      this.Tabledata2 = this.Tabledata2.concat(arr)
    },

    //编辑职位
    edit_job(index) {
      this.$refs.AddPosition.formValidate = JSON.parse(JSON.stringify(this.Tabledata2[index]))
      //改变状态为修改
      this.$refs.AddPosition.is_edit = index
      this.$refs.AddPosition.add_Position = true
      //当前页面保存编辑的index
      this.edit_index = index
    },

    //编辑职位返回数据
    editPost(data) {
      this.Tabledata2.splice(this.edit_index, 1, data)
    },

    detele_job(index) {
      this.$Modal.confirm({
        title: "确定删除该招聘职位吗？",
        onOk: () => {
          this.Tabledata2.splice(index, 1)
        }
      })
    },

    //新增招聘职位
    add_Position() {
      this.$refs.AddPosition.add_Position = true
    },

    //添加职位返回数据
    addPost(data) {
      this.Tabledata2.push(data)
    },
    //导入历史职位
    import_Job() {
      this.$refs.JobImport.is_import_show = true
    },




    //提交
    submit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          //验证参会代表及职位是否存在
          if (this.Tabledata.length && this.Tabledata2.length) {
            if (this.$route.query.id) {
              //编辑 
              this.loading = true
              this.app("enterpriseOperators", "orderJobFair_v3", {
                planId: this.$route.query.id,
                delegates: this.Tabledata,
                positions: this.Tabledata2,
                welfares: this.welfares,
                remark: '',
                mobile: localStorage.getItem('firm_mobile'),
              }).then(res => {
                this.loading = false
                if (res.code == 200) {
                  this.$Message.success("编辑成功")
                  localStorage.setItem("jobfair_welfares", JSON.stringify(this.welfares))
                } else {
                  this.$Message.success(res.message)
                }

                this.$router.push({ name: "job_fair_new" })
              })
            } else {
              // 新报名
              this.loading = true
              this.app("enterpriseOperators", "orderJobFair_v3", {
                planId: localStorage.getItem("planId"),
                delegates: this.Tabledata,
                positions: this.Tabledata2,
                welfares: this.welfares,
                remark: '',
                mobile: localStorage.getItem('firm_mobile'),
              }).then(res => {
                this.loading = false
                if (res.code == 200) {
                  this.$Message.success("报名成功")
                  localStorage.setItem("jobfair_welfares", JSON.stringify(this.welfares))
                } else {
                  this.$Message.success(res.message)
                }
                this.$router.push({ name: "job_fair_new" })
              })

            }
          } else {
            this.$Modal.warning({
              title: "参会代表及招聘职位需至少填写一个"
            })
          }
        } else {
          this.$Modal.warning({
            title: "请填写至少一个福利"
          })
        }
      });
    },

    //封装获取员工信息
    get_staff_info() {
      this.app("enterpriseOperators", "getEmployeeMassage", {
        mobile: localStorage.getItem("firm_mobile"),
      }).then(res => {
        this.Admin = res.data
      })
    },


    handleBeforeUnload(event) {
      // 兼容火狐的做法
      event.preventDefault()
      event.returnValue = ''

      const message = '确定要离开吗？'
      event.returnValue = message
      return message
    },

    onPopState(e) {
      // 监听到浏览器回退事件（这里提示用的confirm，是可以自定义的）
      if (confirm('新增或修改的数据未保存，您确定离开当前页面吗？')) {
        // 点击确定回退
        window.removeEventListener('popstate', this.onPopState)
        window.history.go(-1)
      } else {
        // 点击取消不回退
        window.history.pushState(null, null, window.location.href)
      }
    },

  },
  created() {

  },
  mounted() {


  },

  activated() {
    window.addEventListener('beforeunload', this.handleBeforeUnload)
    window.addEventListener('unload', this.handleBeforeUnload)
    window.history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', this.onPopState);

  
    console.log(this.$route.query.id);
    this.get_staff_info()

    // 获取修改招聘会信息
    if (this.$route.query.id) {
      this.app("enterpriseOperators", "getJobFairOrder", {
        _id: this.$route.query.id,
      }).then(res => {
        this.Tabledata = res.data.personals //参会代表
        this.Tabledata2 = res.data.positions //招聘职位
        this.welfares = res.data.welfares //企业福利
      })
    } else {
      // console.log(JSON.parse(localStorage.getItem("jobfair_welfares")));
      // if (localStorage.getItem("jobfair_welfares")) {
      //   this.welfares = JSON.parse(localStorage.getItem("jobfair_welfares"))
      // }
      this.app("enterpriseOperators", "getOneOrderJobFairByMobile", {
        mobile: localStorage.getItem('firm_mobile')
      }).then(res => {
        this.welfares = res.data.welfares

       
      })
    }
  },
  // destroyed() {
  //   window.removeEventListener('beforeunload', this.handleBeforeUnload)
  //   window.removeEventListener('unload', this.handleBeforeUnload)
  // },

  deactivated() {

    window.removeEventListener('beforeunload', this.handleBeforeUnload)
    window.removeEventListener('unload', this.handleBeforeUnload)

    window.removeEventListener('popstate', this.onPopState)
  }



}
</script>

<style lang="less" scoped>
@import "./job_fair_details_new.less";
</style>
