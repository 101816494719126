<!-- 添加参会代表信息 -->
<template>
  <div class="add_job">
    <Modal v-model="add_job" title="添加参会人员" @on-visible-change="modalChange">
      <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="60">

        <FormItem label="姓名" prop="allName">
          <Input v-model="formValidate.allName" placeholder="姓名"></Input>
        </FormItem>

        <FormItem label="职位" prop="position">
          <Input v-model="formValidate.position" placeholder="职位"></Input>
        </FormItem>

        <FormItem label="手机" prop="mobile">
          <Input v-model="formValidate.mobile" placeholder="手机"></Input>
        </FormItem>

        <FormItem label="邮箱" prop="email">
          <Input v-model="formValidate.email" placeholder="邮箱"></Input>
        </FormItem>

        <FormItem label="座机">
          <Input v-model="formValidate.setPhone" placeholder="座机"></Input>
        </FormItem>

        <FormItem label="QQ">
          <Input v-model="formValidate.qq" placeholder="QQ"></Input>
        </FormItem>

        <p>温馨提示：进场请佩戴口罩。</p>

        <p style="text-align: center; color: red">标红星项为必填项，请按要求填写！</p>
      </Form>

      <div slot="footer">
        <Button type="text" @click="add_job = false">取消</Button>
        <Button :loading="but_loading" type="primary" @click="handleSubmit('formValidate')">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      add_job: false,
      but_loading: false,
      formValidate: {
        allName: "",
        position: "",
        mobile: "",
        email: "",
      },
      ruleValidate: {
        allName: [{ required: true, message: "请填写姓名", trigger: "change" }],
        position: [{ required: true, message: "请填写职位", trigger: "change" }],
        mobile: [{ required: true, message: "请填写手机", trigger: "change" }],
        email: [{ required: true, message: "请填写邮箱", trigger: "change" }],

      }
    }
  },
  props: ["Admin"],
  methods: {

    //开/关
    modalChange(bool) {
      if (bool) {
        this.$refs["formValidate"].resetFields();
        if (localStorage.getItem("last_add_manage")) {
          this.formValidate = JSON.parse(localStorage.getItem("last_add_manage"))
        } else {
          this.formValidate.allName = this.Admin.name
          this.formValidate.position = this.Admin.position
          this.formValidate.mobile = this.Admin.mobile
          this.formValidate.email = this.Admin.email
        }

      }
    },
    //提交
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {

          let new_data = JSON.parse(JSON.stringify(this.formValidate))
          this.$emit("add_info", new_data)
          this.add_job = false
          //记录最近一次储存的信息
          localStorage.setItem("last_add_manage", JSON.stringify(this.formValidate))
          this.$Message.success("添加参会成员成功")
        } else {
          this.$Message.error("请填写完整信息");
        }
      });
    }
  },
  created() {

  }
}
</script>

<style lang="less" scoped>
</style>
